@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

body {
	margin: 0;
	font-family: "Montserrat", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.MuiTypography-root {
	font-family: "Montserrat", sans-serif !important;
}

html::-webkit-scrollbar {
	display: none;
}

html {
	-ms-overflow-style: none;
	scrollbar-width: none;
	scroll-behavior: smooth;
}

.transition_cus {
	transition: all 0.25s ease-in-out;
}

.wrap-content {
	max-width: 996px;
	margin: 0 auto;
}

@media (max-width: 1200px) {
	.wrap-content {
		padding: 0 10px;
	}
}

#hiddenCursor {
	cursor: none;
}

.text_linear {
	background: -webkit-linear-gradient(45deg, #6ddbfe, #fe55f4);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 600 !important;
}

.light .dash_linear {
	border-radius: 3px;
	background: linear-gradient(#fff 0 0) padding-box,
		linear-gradient(90deg, #6ddbfe, #fe55f4) border-box;
}

.dark .dash_linear {
	border-radius: 3px;
	background: linear-gradient(#303030 0 0) padding-box,
		linear-gradient(90deg, #6ddbfe, #fe55f4) border-box;
}

.res_text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.konvajs-content canvas:nth-child(2) {
	opacity: 0.4;
}

div#idCanvas {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	border-radius: 10px;
}

div#idCanvas .konvajs-content {
	width: 100% !important;
	max-height: 100% !important;
	z-index: 2;
}

/* ================ SCROLL BAR CSS =============== */

::-webkit-scrollbar {
	width: 0px;
	height: 5px;
}

::-webkit-scrollbar-track {
	background: rgba(128, 126, 126, 0.411);
	border-radius: 99999px;
}

::-webkit-scrollbar-thumb {
	background: #46444446;
	border-radius: 99999px;
	cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
	background: #1f1f1f80;
	transition: linear 300ms;
}

.toast-container {
	z-index: 999999999 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.box_color::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.box_color {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* ================ CUSTOM SELECT OPTIONS ================== */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
	border-radius: 12px;
}

.MuiFormControl-root .MuiBox-root svg {
	stroke: white;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline::after,
.MuiInputBase-root.MuiInput-root.MuiInput-underline::before {
	content: none;
}

.MuiSelect-select.MuiSelect-standard.MuiInputBase-input {
	background-color: transparent !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
	display: none;
}

.MuiInputBase-root.MuiInput-root,
.MuiButtonBase-root.MuiMenuItem-root {
	font-family: "Montserrat", sans-serif !important;
}

.MuiBox-root {
	outline: none;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
	transition: all 0.25s ease-in-out;
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
	transition: none !important;
}

/* ================ ANIMATION CSS ================= */
.rotate-animation {
	animation: rotate360 1.7s infinite linear;
}

@keyframes rotate360 {
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(360deg);
	}
}

/* ============ FIX DISPLAY IMAGE ================= */
.lazy-load-image-background {
	display: grid !important;
	width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
	outline: none;
}

.left-button .MuiButtonBase-root.MuiIconButton-root {
	background: transparent !important;
	backdrop-filter: "blur(10px)";
}

:nth-child(1 of .left-button) {
	right: -33px !important;
}
:nth-last-child(1 of .left-button) {
	left: -33px !important;
}

@keyframes leaves {
	0% {
		transform: scale(0.8);
		-webkit-transform: scale(0.8);
	}
	100% {
		transform: scale(1.2);
		-webkit-transform: scale(1.2);
	}
}

#treeLeaves {
	animation: leaves 0.55s ease-in-out infinite alternate;
	-webkit-animation: leaves 0.55s ease-in-out infinite alternate;
}
